import styled from '@emotion/styled'
import { BigWheelWinContainer, WinBgContainerPt, WinBgContainerEn, WinBgContainerEs } from 'assets/images';
import { mixins } from 'theme';

export const CoinContainer = styled.div`
  z-index: 2;
  position: relative;
  width: 290px;
  height: 60px;
  /* background: url(${BigWheelWinContainer}); */
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
  font-size: 20px;
  color: white;
  font-family: Roboto;
  font-weight: 700;
  .total-earning {
    width: 87%;
    margin-right: 10px;
    margin-top: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.coin.win-es {
    background: url(${WinBgContainerEs});
    background-size: 100% 100%;
  }
  &.coin.win-pt {
    background: url(${WinBgContainerPt});
    background-size: 100% 100%;
  }
  &.coin.win-en {
    background: url(${WinBgContainerEn});
    background-size: 100% 100%;
  }

  @media (max-width: ${mixins.mobileWidth}px) {
    .total-earning {
      padding-left: 20px;
      span {
        font-size: 20px;
      }
    }
  }
`;